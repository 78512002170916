<template>
  <div>
    <shop-head currentPageName="SHOP LIST" @reload="searchProduct"></shop-head>
    <!-- 面包屑 -->
    <breadcrumb pageName="Product List"></breadcrumb>
    <!-- 主要内容 -->
    <div class="content_wrapper">
      <div class="container">
        <el-row :gutter="30" type="flex" style="flex-wrap: wrap">
          <!-- <el-col :md="6" class="order-2 order-lg-1">
            <div class="sidebar-catagories_area"> -->
          <!-- 价格过滤 -->
          <!-- <div class="sidebar_categories d-none d-lg-block">
                <div class="categories_title first-child">
                  <h5>Filter by price</h5>
                </div>
                <div class="price-filter">
                  <el-slider v-model="priceRange" range :max="278"></el-slider>
                </div>
                <div class="price-slider-amount">
                  <div>Price：</div>
                  <div>$</div>
                  <input v-model="priceRange[0]" type="text" class="price-input">
                  <div style="padding-right: 30px;">-</div>
                  <input v-model="priceRange[1]" type="text" class="price-input">
                  <button @click="searchByPrice" class="filter-btn">Filter</button>
                </div>
              </div> -->
          <!-- 分类 -->
          <!-- <div class="sidebar_categories d-none d-lg-block">
                <div class="categories_title">
                  <h5>Product Categories</h5>
                </div>
                <ul class="sidebar_categories-box">
                  <li @click="filterCategory(item.category,index)" v-for="(item,index) in categoryList" :key="item.name" :class="{'active': index === categoryIndex}">{{ item.name }}</li>
                </ul>
              </div> -->
          <!-- 侧边 -->
          <!-- <div class="sidebar_categories">
                <div class="categories_title">
                  <h5>Compare</h5>
                </div>
                <div v-for="item in compareList" :key="item.id" class="sidebar_item">
                  <div class="product-img">
                    <a>
                      <el-image :src="item.imgs[0]" fit="contain">
                        <div slot="error" class="image-slot">
                          <img src="../../assets/images/empty-img.jpg" alt="" class="primary-img">
                        </div>
                      </el-image>
                    </a>
                  </div>
                  <div class="product-content">
                    <div class="product-name ellipsis-more" :title="item.name">{{ item.name }}<br>{{ item.no }}</div>
                    <div class="price-box"> -->
          <!-- <span>$85.00</span> -->
          <!-- <span class="new-price">${{ $utils.keepTwoDecimalFull(item.foreignPrice) }}</span>
                    </div>
                    <div class="rating-box">
                      <i v-for="item in 4" :key="item" class="iconfont icon-pingfendengjiRating1"></i>
                      <i class="iconfont icon-shuyi_xing-pingfen gray"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          <!-- 小banner -->
          <!-- <div class="shop-banner_area">
              <div class="img-hover_effect">
                <img src="../../assets/images/shop-bottom.png" alt="">
              </div>
              <div class="banner-content">
                <div>The Brake System</div>
                <router-link to="/shop">
                  <div class="btn-ps_center">Shop Now</div>
                </router-link>
              </div>
            </div>
          </el-col> -->
          <el-col :md="24" class="order-1 order-lg-2"
            ><!-- :md="18" -->
            <!-- banner -->
            <!-- <div class="shop-banner_area">
              <div class="img-hover_effect">
                <img src="../../assets/images/shopBanner.png" alt="">
              </div>
            </div> -->
            <!-- 产品列表 -->
            <div class="shop-toolbar">
              <div class="product-view-mode">
                <el-tooltip
                  effect="dark"
                  :content="isList ? 'List View' : 'Grid View'"
                  placement="top"
                >
                  <i
                    @click="toggleListMode"
                    :class="{
                      'iconfont active': true,
                      'icon-viewList-fill': isList,
                      'icon-xiaosuolvetu': !isList,
                    }"
                  ></i>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  content="Alphabetic Sort"
                  placement="top"
                  style="font-size: 22px; vertical-align: middle"
                >
                  <i
                    @click="changeSort"
                    :class="{
                      iconfont: true,
                      'icon-paixu':
                        alphabeticSort === 1 || alphabeticSort === 0,
                      'icon-sort-descending': alphabeticSort === 2,
                      active: alphabeticSort === 1 || alphabeticSort === 2,
                    }"
                  ></i>
                </el-tooltip>
                <el-tooltip effect="dark" content="Price Sort" placement="top">
                  <div
                    @click="changeSort('price')"
                    :class="{
                      'price-sort': true,
                      active: priceSort === 1 || priceSort === 2,
                    }"
                  >
                    <i
                      :class="{
                        'el-icon-sort-down': priceSort === 1 || priceSort === 0,
                        'el-icon-sort-up': priceSort === 2,
                      }"
                    ></i>
                    <i class="iconfont icon-jiagepaixu"></i>
                  </div>
                </el-tooltip>
              </div>
              <!-- <div class="product-page_count">Showing {{ (page - 1) * pageSize + 1 }}–{{ page * pageSize }} of {{ productList.length }} results</div> -->
              <!-- <div class="product-item-selection_area">
                Short by：
                <el-select v-model="shortType" @change="searchProduct" size="medium">
                  <el-option v-for="item in shortTypeList" :key="item.keyS" :label="item.name" :value="item.keyS" style="width: 180px;"></el-option>
                </el-select>
              </div> -->
              <!-- <div @click="toggelFilter" class="d-block d-lg-none" style="cursor: pointer;">
                <i class="iconfont icon-24gf-funnel"></i>
              </div> -->
            </div>
            <el-row v-if="!isList" :gutter="30">
              <!-- <el-col v-for="item in currentProductList" :key="item.id" :md="8" :sm="12" :xs="12"> -->
              <el-col
                v-for="item in productList"
                :key="item.id"
                :md="6"
                :sm="12"
                :xs="12"
              >
                <div @click="gotoDetail(item.id)" class="single-product">
                  <div class="product-img">
                    <el-image
                      :src="item.imgs[0]"
                      fit="contain"
                      class="primary-img"
                    >
                      <div slot="error" class="image-slot">
                        <img
                          src="../../assets/images/empty-img.jpg"
                          alt=""
                          class="primary-img"
                        />
                      </div>
                    </el-image>
                    <div class="add-actions">
                      <ul>
                        <li @click.stop="addToCart(item)">
                          <el-tooltip
                            effect="dark"
                            content="Add To Cart"
                            placement="top"
                          >
                            <i class="el-icon-goods"></i>
                          </el-tooltip>
                        </li>
                        <!-- <li @click="showQuickView">
                          <el-tooltip effect="dark" content="Add To Wishlist" placement="top">
                            <i class="el-icon-star-off"></i>
                          </el-tooltip>
                        </li> -->
                        <li @click.stop="showQuickView(item)">
                          <el-tooltip
                            effect="dark"
                            content="Quick View"
                            placement="top"
                          >
                            <i class="el-icon-search"></i>
                          </el-tooltip>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="product-content">
                    <div class="price-box">
                      <span class="new-price"
                        >${{
                          $utils.keepTwoDecimalFull(item.foreignPrice)
                        }}</span
                      >
                      <i
                        v-if="userInfo && userInfo.id"
                        class="iconfont icon-kefu"
                        @click="contactService(item)"
                      ></i>
                      <!-- <span class="old-price">$100.00</span> -->
                    </div>
                    <div class="product-name ellipsis-more" :title="item.name">
                      {{ item.name }}<br />{{ item.no }}
                    </div>
                    <div class="rating-box">
                      <i
                        v-for="item in 4"
                        :key="item"
                        class="iconfont icon-pingfendengjiRating1"
                      ></i>
                      <i class="iconfont icon-shuyi_xing-pingfen gray"></i>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <div v-else>
              <div
                @click="gotoDetail(item.id)"
                v-for="item in productList"
                :key="item.id"
                class="list-product"
              >
                <div class="list-img">
                  <el-image :src="item.imgs[0]" fit="contain">
                    <div slot="error" class="image-slot">
                      <img
                        src="../../assets/images/empty-img.jpg"
                        alt=""
                        class="primary-img"
                      />
                    </div>
                  </el-image>
                </div>
                <div class="list-content">
                  <div class="price-box">
                    <span class="new-price"
                      >${{ $utils.keepTwoDecimalFull(item.foreignPrice) }}</span
                    >
                    <!-- <span class="old-price">$100.00</span> -->
                  </div>
                  <div class="product-name">
                    {{ item.name }}<br />{{ item.no }}
                  </div>
                  <div class="rating-box">
                    <i
                      v-for="item in 4"
                      :key="item"
                      class="iconfont icon-pingfendengjiRating1"
                    ></i>
                    <i class="iconfont icon-shuyi_xing-pingfen gray"></i>
                  </div>
                  <!-- <div class="list-short_desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco,Proin lectus ipsum, gravida et mattis vulputate, tristique ut lectus</div> -->
                  <div class="add-actions">
                    <ul>
                      <li @click.stop="addToCart(item)">
                        <el-tooltip
                          effect="dark"
                          content="Add To Cart"
                          placement="top"
                        >
                          <i class="el-icon-goods"></i>
                        </el-tooltip>
                      </li>
                      <!-- <li @click="showQuickView">
                        <el-tooltip effect="dark" content="Add To Wishlist" placement="top">
                          <i class="el-icon-star-off"></i>
                        </el-tooltip>
                      </li> -->
                      <li @click.stop="showQuickView(item)">
                        <el-tooltip
                          effect="dark"
                          content="Quick View"
                          placement="top"
                        >
                          <i class="el-icon-search"></i>
                        </el-tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="paginatoin-area">
              <el-pagination
                @current-change="changePage"
                :total="count"
                :page-size="pageSize"
                prev-text="Pre"
                next-text="Next"
                :current-page="page"
                layout="prev, pager, next"
              ></el-pagination>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <common-footer></common-footer>
    <el-dialog :visible.sync="showDetail" width="80%">
      <quick-view ref="quickView"></quick-view>
    </el-dialog>
    <!-- 侧边弹窗 -->
    <el-drawer :visible.sync="showFilterDialog" size="300px" direction="rtl">
      <div class="content_wrapper" style="padding: 0 20px">
        <!-- 价格过滤 -->
        <div class="sidebar_categories">
          <div class="categories_title first-child">
            <h5>Filter by price</h5>
          </div>
          <div class="price-filter">
            <el-slider v-model="priceRange" range :max="278"></el-slider>
          </div>
          <div class="price-slider-amount">
            <div>Price：</div>
            <div>$</div>
            <input v-model="priceRange[0]" type="text" class="price-input" />
            <div style="padding-right: 30px">-</div>
            <input v-model="priceRange[1]" type="text" class="price-input" />
            <button @click="searchByPrice(true)" class="filter-btn">
              Filter
            </button>
          </div>
        </div>
        <!-- 分类 -->
        <div class="sidebar_categories">
          <div class="categories_title">
            <h5>Product Categories</h5>
          </div>
          <ul class="sidebar_categories-box">
            <li
              @click="filterCategory(item.category, index, true)"
              v-for="(item, index) in categoryList"
              :key="item.name"
              :class="{ active: index === categoryIndex }"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import quickView from "../../components/shop/quickView";
import shopHead from "../../components/shop/head";
import commonFooter from "../../components/common/footer";
import breadcrumb from "../../components/common/breadcrumb";
import { mapGetters, mapActions } from "vuex";
import { createCrat } from "@/api/service/commonApi";
export default {
  components: {
    quickView,
    shopHead,
    commonFooter,
    breadcrumb,
  },
  data() {
    return {
      sortAs: "",
      searchKey: "",
      showFilterDialog: false,
      showDetail: false,
      priceRange: [0, 278],
      isList: false,
      shortType: 1,
      shortTypeList: [
        {
          keyS: 1,
          name: "Default sorting",
        },
        {
          keyS: 2,
          name: "Name, A to Z",
        },
        {
          keyS: 3,
          name: "Name, Z to A",
        },
        {
          keyS: 4,
          name: "Price, low to high",
        },
        {
          keyS: 5,
          name: "Price, high to low",
        },
      ],
      alphabeticSort: 0, //0:默认 1:A-Z 2:Z-A
      priceSort: 0, //0:默认 1:价格升序 2:价格降序
      productList: [],
      page: 1,
      pageSize: 8,
      count: 0,
      compareList: [],
      categoryList: [
        {
          name: "ALL",
          category: "",
        },
        {
          name: "AIP FILTER",
          category: "Air Filters",
        },
        {
          name: "OIL FILTER",
          category: "Oil Filters",
        },
        {
          name: "IGNTION COIL",
          category: "Ignition Coils",
        },
        {
          name: "OTHER",
          category: "Other",
        },
      ],
      categoryIndex: 0,
    };
  },
  watch: {
    showDetail(val) {
      if (!val) {
        this.$nextTick(() => {
          this.$refs.quickView.resetData();
        });
      }
    },
  },
  created() {
    let searchKey = this.$route.query.key;
    if (searchKey) {
      this.searchKey = searchKey;
    }
    this.getProductList();
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    ...mapActions(["getCartList"]),
    /** 搜索 */
    searchProduct(val) {
      this.searchKey = val;
      this.$route.query.key = val;
      this.page = 1;
      this.getProductList();
    },
    /** 获取产品列表 */
    getProductList() {
      let param = {
        page: this.page,
        pageSize: this.pageSize,
      };
      let agencyLevel = window.sessionStorage.getItem("agencyLevel");
      if (
        agencyLevel !== "" &&
        agencyLevel !== null &&
        agencyLevel !== undefined
      ) {
        param.agencyLevel = agencyLevel;
      }
      if (
        this.searchKey !== "" &&
        this.searchKey !== undefined &&
        this.searchKey !== null
      ) {
        param.searchKey = this.searchKey;
      }
      if (
        this.sortAs !== "" &&
        this.sortAs !== undefined &&
        this.sortAs !== null
      ) {
        param.sortAs = this.sortAs;
      }
      this.$axios
        .post("/foreign/searchProduct/searchProductPage", param)
        .then((res) => {
          if (res.successful) {
            this.productList = res.resultSet;
            this.count = res.count;
          }
        });
    },
    toggelFilter() {
      this.showFilterDialog = !this.showFilterDialog;
    },
    /** 筛选类别 */
    filterCategory(category, index, isDialog = false) {
      this.categoryIndex = index;
      let notOther = ["Air Filters", "Oil Filters", "Ignition Coils"];
      this.page = 1;
      let arr = JSON.parse(JSON.stringify(this.$productList.productList));
      //全部
      if (category === "") {
        this.productList = JSON.parse(
          JSON.stringify(this.$productList.productList)
        );
      } else if (notOther.includes(category)) {
        this.productList = arr.filter((item) => {
          return item.categoryName === category;
        });
      } else {
        this.productList = arr.filter((item) => {
          return !notOther.includes(item.categoryName);
        });
      }
      if (isDialog) {
        this.showFilterDialog = false;
      }
    },
    /** 跳转详情 */
    gotoDetail(id) {
      // this.$router.push({
      //   path: '/shopDetail',
      //   query: {
      //     id: id
      //   }
      // })
    },
    /** 加入购物车 */
    addToCart(item) {
      let query = {
        partsId: item.id,
        quantity: 1,
        partsName: item.name,
        partsNo: item.no,
        sellPrice: item.sellPrice,
      };
      createCrat(query).then((res) => {
        if (res.data.bizCode.code === "0") {
          this.$message.success("Success");
          this.getCartList();
        } else {
          this.$message.error(res.data.bizCode.info);
        }
      });
    },
    changeSort(type) {
      this.page = 1;
      if (type === "price") {
        this.alphabeticSort = 0;
        this.priceSort++;
        if (this.priceSort > 2) {
          this.priceSort = 0;
        }
        if (this.priceSort === 1) {
          //价格升序
          this.sortAs = "sellPrice ASC";
        } else if (this.priceSort === 2) {
          //价格降序
          this.sortAs = "sellPrice DESC";
        } else {
          this.sortAs = "";
        }
      } else {
        this.priceSort = 0;
        this.alphabeticSort++;
        if (this.alphabeticSort > 2) {
          this.alphabeticSort = 0;
        }
        //字母排序默认
        if (this.alphabeticSort === 0) {
          this.sortAs = "";
        } else if (this.alphabeticSort === 1) {
          //字母Z-A
          this.sortAs = "ename ASC";
        } else {
          //字母Z-A
          this.sortAs = "ename DESC";
        }
      }
      this.getProductList();
    },
    /** 价格筛选 */
    searchByPrice(isDialog = false) {
      this.page = 1;
      let arr = this.productList.filter((item) => {
        return (
          item.price >= this.priceRange[0] && item.price <= this.priceRange[1]
        );
      });
      if (isDialog) {
        this.showFilterDialog = false;
      }
    },
    /** 改变页码 */
    changePage(val) {
      this.page = val;
      this.getProductList();
    },
    /** 快速查看 */
    showQuickView(item) {
      this.showDetail = true;
      this.$nextTick(() => {
        this.$refs.quickView.productData = item;
      });
    },
    /** 切换产品显示模式 */
    toggleListMode(val) {
      this.isList = !this.isList;
    },
    contactService(item) {
      let data = {
        portraitUri:
          "https://apbenben.oss-cn-shenzhen.aliyuncs.com/group1/CF4AF382602141E7B4E1B262935D5F30.png",
        name: `BBmart-service`,
        code: "56A063FBE6454074AD739E8F6F0E4371",
        type: 1,
        isService: true,
        source: "价格查询", // 正在沟通来源
        carPartsId: item.productSkuId,
      };
      this.$store.dispatch("sendContactMsg", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-box {
  width: 100%;
  color: #fed100;

  .iconfont {
    font-size: 12px;
  }

  .gray {
    color: #c0c0c0;
  }
}

.content_wrapper {
  padding: 0 0 60px;

  h5 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
    padding-bottom: 25px;
  }

  /** 价格过滤 */
  .sidebar-catagories_area {
    @media only screen and (max-width: 991px) {
      padding-top: 60px;
    }
  }

  .sidebar_categories {
    background: #fff;
    padding-top: 20px;

    .sidebar_categories-box {
      li {
        line-height: 1;
        font-size: 15px;
        padding-bottom: 20px;
        cursor: pointer;

        &:hover,
        &.active {
          color: #fed100;
        }
      }
    }

    .categories_title {
      border-bottom: 1px solid #ebebeb;
      position: relative;
      padding-top: 20px;
      margin-bottom: 20px;
      text-align: center;

      &.first-child {
        padding-top: 0;
      }
    }

    .price-filter {
      margin-top: 35px;

      ::v-deep .el-slider__bar {
        background: #fed100;
      }

      ::v-deep .el-slider__button {
        border-color: #fed100;
      }
    }

    .price-slider-amount {
      width: 100%;
      height: auto;
      line-height: 24px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .price-input {
        width: 40px;
        line-height: 24px;
        height: 24px;
        border: none;
        outline: none;
      }
    }

    .filter-btn {
      border: 1px solid #ebebeb;
      color: #999999;
      width: 85px;
      height: 40px;
      line-height: 39px;
      border-radius: 50px;
      background: none;

      &:hover {
        background-color: #fed100;
        border-color: #fed100;
        color: #ffffff;
      }
    }
  }

  /** 侧边 */
  .sidebar_categories {
    .categories_title {
      border-bottom: 1px solid #ebebeb;
      position: relative;
      padding-top: 20px;
      margin-bottom: 30px;
      text-align: center;
    }

    .sidebar_item {
      width: 100%;
      border: 1px solid #ebebeb;
      display: flex;
      align-items: center;
      padding: 30px 10px;
      border-radius: 3px;
      margin-bottom: 30px;

      .product-img {
        width: 130px;
        flex: 0 0 130px;

        @media only screen and (min-width: 992px) {
          width: 60px;
          flex: 0 0 60px;
        }

        img {
          width: 100%;
        }
      }

      .product-content {
        flex: 1;
        padding-top: 25px;
        padding-left: 15px;

        .product-name {
          width: 100%;
          line-height: 13px;
          font-size: 13px;
          font-weight: 400;
          margin-bottom: 10px;
        }

        .price-box {
          font-size: 13px;

          .new-price {
            font-size: 16px;
            font-weight: 600;
            color: #e01212;
            margin-left: 5px;
          }
        }
      }
    }
  }

  /** banner */
  .shop-banner_area {
    width: 100%;
    padding-bottom: 25px;
    position: relative;

    .img-hover_effect {
      width: 100%;
      position: relative;
      display: block;
      overflow: hidden;

      img {
        width: 100%;
        transform: scale(1);
        transition: all 0.3s ease-in;
        display: block;
      }
    }

    &:hover {
      .img-hover_effect {
        img {
          transform: scale(1.06);
          transition: all 0.3s ease-in;
        }
      }
    }

    .banner-content {
      width: 90%;
      line-height: 1;
      font-size: 20px;
      color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      .btn-ps_center {
        width: 135px;
        height: 40px;
        line-height: 38px;
        font-size: 15px;
        color: #ffffff;
        font-weight: 600;
        border: 0;
        border-radius: 20px;
        background: #fed100;
        margin: 25px auto 0;
      }
    }
  }

  /** 产品列表 */
  .shop-toolbar {
    padding: 10px 0 25px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    .product-view-mode {
      .price-sort {
        width: auto;
        height: auto;
        font-size: 22px;
        color: #888;
        vertical-align: middle;
        position: relative;
        display: inline-block;

        &.active {
          color: #fed100;

          .iconfont {
            color: #fed100;
          }
        }

        .icon-jiagepaixu {
          font-size: 12px;
          color: #333;
          position: absolute;
          right: -15px;
          bottom: -5px;
        }
      }

      .iconfont {
        vertical-align: middle;
        color: #999;
        margin-right: 10px;
        cursor: pointer;

        &.active {
          color: #fed100;
        }
      }

      .icon-xiaosuolvetu {
        font-size: 24px;
      }

      .icon-viewList-fill {
        font-size: 30px;
      }
    }
  }

  .single-product {
    width: 100%;
    text-align: center;
    border: 1px solid #ebebeb;
    padding: 30px 10px;
    border-radius: 3px;
    margin-bottom: 30px;
    cursor: pointer;

    .product-img {
      overflow: visible;
      position: relative;
      text-align: center;
      position: relative;
      width: 100%;
      padding-bottom: 100%;

      .primary-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .add-actions {
        width: 100%;
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);

        li {
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 20px;
          text-align: center;
          opacity: 0;
          background-color: #fed100;
          border-radius: 100%;
          display: inline-block;
          vertical-align: middle;
          margin: 0 5px;
          transition: all 0.3s ease-in;

          &:nth-child(1) {
            transition-delay: 100ms;
          }

          &:nth-child(2) {
            transition-delay: 150ms;
          }

          &:nth-child(3) {
            transition-delay: 200ms;
          }
        }
      }
    }

    &:hover {
      border-color: #fed100;

      .add-actions {
        li {
          opacity: 1;
        }
      }
    }

    .product-content {
      padding-top: 25px;
    }
  }

  .price-box {
    padding-bottom: 15px;
    position: relative;

    .new-price {
      color: #e01212;
      font-size: 22px;
      font-weight: 600;
    }

    .old-price {
      color: #c0c0c0;
      text-decoration: line-through;
      font-size: 16px;
      display: inline-block;
      margin-left: 5px;
    }

    .icon-kefu {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 99;
      color: #e01212;
      cursor: pointer;
      font-size: 20px;
    }
  }

  .product-name {
    font-size: 13px;
    line-height: 1;
    padding-bottom: 5px;
  }

  .list-product {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    padding: 15px 25px;
    overflow: hidden;
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;

    .list-img {
      width: 31%;
      flex: 0 0 31%;
      position: relative;
      padding-bottom: 31%;

      .el-image {
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .image-slot {
          img {
            width: 100%;
          }
        }
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        flex: 0 0 100%;
        padding-bottom: 100%;
      }
    }

    .list-content {
      flex: 1;
      padding-left: 15px;

      .product-name {
        font-size: 16px;
        padding-bottom: 10px;
      }

      .list-short_desc {
        margin-bottom: 0;
        padding-bottom: 23px;
      }

      .add-actions {
        width: 100%;

        li {
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 20px;
          text-align: center;
          background-color: #fed100;
          border-radius: 100%;
          display: inline-block;
          vertical-align: middle;
          margin: 0 5px;
        }
      }
    }
  }

  .paginatoin-area {
    padding-top: 30px;
    text-align: center;

    ::v-deep.el-pagination {
      line-height: 48px;

      button {
        width: auto;
        height: 48px;
        border: 2px solid #fed100;
        box-sizing: border-box;
        padding: 0 25px;

        span {
          font-size: 15px;
        }

        &:hover {
          color: #fed100;
        }
      }

      .btn-next {
        padding-left: 25px;
        border-radius: 0 24px 24px 0;
        border-left: 0;
      }

      .btn-prev {
        padding-right: 25px;
        border-radius: 24px 0 0 24px;
        border-right: 0;
      }

      .el-pager {
        height: 48px;
        padding: 12px 0;
        box-sizing: border-box;
        border-top: 2px solid #fed100;
        border-bottom: 2px solid #fed100;

        li {
          height: 20px;
          line-height: 20px;
          font-size: 15px;
          font-weight: normal;
          border-left: 1px solid #fed100;
          padding: 0 25px;

          &:last-of-type {
            border-right: 1px solid #fed100;
          }

          &.active {
            color: #fed100;
          }

          &:hover {
            color: #fed100;
          }
        }
      }
    }
  }
}

.ellipsis-more {
  line-height: 24px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /*超出2行就显示省略号，可以填3或者其它正整数*/
}
</style>
